.carrinho {
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    flex: auto;
    margin-top: 2%;

}

.allPage {
    background: #E0EAFC;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #CFDEF3, #E0EAFC);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.checkInfo {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -4%;
    margin-bottom: 0%;
}


.checkoutBody {
    align-items: center;
    justify-content: center;
    margin-left: 15%;
    margin-right: 15%;
    flex-wrap: wrap;
    flex: auto;
    margin-top: 4%;
    margin-bottom: 1%;

}

.preco {
    border: 1px solid rgba(0, 0, 0, .125);
}

.total {
    border: 1px solid rgba(0, 0, 0, .125)
}

.fourColumns {
    margin-left: 0%;
    margin-right: 0%;

}

.firstColumn {
    flex: center;
    border-radius: 2rem;
    border-width: 100px;
    flex-wrap: wrap;
    flex: auto;
    margin-top: 10px;
}

.titleCarrinho {
    color: gray;
}

.hr {
    border: 0;
    border-top: 1px solid #7d818b;
    margin-top: 1%;
    margin-bottom: 1%;
}

.buttonCheckout {
    color: white;
    border-radius: 20px;
    size: 200px;
    margin-left: 10;
    justify-content: start;
}

.buttonEndCheckout {
    background-color: blue;
    color: white;
    border-radius: 20px;
    size: 200px;
    width: 200px;
    height: 50px;
    font-size: large;

}

.RenachTeste {
    margin-left: -15px;
}

.buttonEndCheckout:hover {
    background-color: rgb(38, 38, 228);
    color: white;
}

.cupom {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;

}


.buttonCupom {
    background-color: rgb(87, 87, 87);
    color: white;
    border-radius: 8px;
    size: 180;
    width: 180px;
    height: 50px;
}

.buttonCupom:hover {
    background-color: rgb(39, 39, 39);
    color: white;
}

.ButtonWhats {
    background-color: rgb(0, 158, 8);
    color: white;
    border-radius: 20px;
    size: 200px;
    width: 200px;
    height: 50px;
    font-size: large;
}

footer {
    background-color: #6c757d;
    text-decoration-color: rgb(131, 131, 131);
    color: white;
}

.logo {
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.whatsapp {
    position: fixed;
    top: 89%;
    right: 1%;
    padding: 10px;
    z-index: 10000000;
}