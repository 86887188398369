@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');




.search_wrap{
	width: 500px;
	margin: 38px auto;
}

.search_wrap .search_box{
	position: relative;
	width: 25rem;
	height: 45px;
}

.search_wrap .search_box .input{
	position: absolute;
	top: 0;
	left: 0;
	width: 80%;
	height: 100%;
	padding: 10px 20px;
	border-radius: 1px;
	font-size: 18px;
}

.search_wrap .search_box .btn{
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 100%;
	background: #7690da;
	z-index: 1;
	cursor: pointer;
}

.search_wrap .search_box .btn:hover{
	background: #708bd2;	
}

.search_wrap .search_box .btn.btn_common .fas{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	color: #fff;
	font-size: 20px;
}

.search_wrap.search_wrap_1 .search_box .btn{
	right: 0;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.search_wrap.search_wrap_1 .search_box .input,
.search_wrap.search_wrap_3 .search_box .input{
	padding-right: 10;
    border-color: whitesmoke;
}

.search_wrap.search_wrap_2 .search_box .btn{
	left: 0;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.search_wrap.search_wrap_2 .search_box .input,
.search_wrap.search_wrap_4 .search_box .input{
	padding-left: 10;
}

.search_wrap.search_wrap_3 .search_box .input,
.search_wrap.search_wrap_4 .search_box .input,
.search_wrap.search_wrap_6 .search_box .input{
	border-radius: 2px;
}

.search_wrap.search_wrap_3 .search_box .btn{
	right: 0px;
	border-radius: 50%;
}

.search_wrap.search_wrap_4 .search_box .btn{
	left: 0px;
	border-radius: 50%;
}

.search_wrap.search_wrap_6 .search_box .btn,
.search_wrap.search_wrap_5 .search_box .btn{
	width: 125px;
	height: 45px;
	top: 8px;
	right: 5px;
	border-radius: 3px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.search_wrap.search_wrap_6 .search_box .btn{
	border-radius: 25px;
}


.search_wrap.search_wrap_5 .search_box .input,
.search_wrap.search_wrap_6 .search_box .input{
	padding-right: 145px;
}