/**=====================
    27. Data-table CSS Start
==========================**/
.product-table {
  h6 {
    font-weight: 600;
    color: $dark-color;
  }
}
.rdt_Table {
  border: 1px solid rgba($black, 0.1);
  list-style: none;

  th:first-child {
    margin-left: -15px;
  }
}
.rdt_TableRow {
  height: 90px;
  div {
    margin-right: 15px;
    // margin-left: -15px;
  }
}

.rdt_TableRow div:last-child {
  padding: 0;
}
.rdt_TableCol {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.rdt_TableHeader {
  display: none !important;
  background: $transparent-color;
}
.ezWsxJ {
  background-color: rgba($primary-color, 0.05) !important;
}
.support-table {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableCell {
        img {
          border-radius: 100%;
        }
      }
    }
  }
}
/**=====================
     27. Data-table CSS Ends
==========================**/
